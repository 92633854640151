export const accreditationStandard = {
  // 工业消费品
  INDUSTRIAL_CONSUMABLES: 'A',
  // 农食产品
  AGRICULTURAL_PRODUCTS: 'B',
  // 服务
  SERVICES: 'C',
  // 绿色建材
  GREEN_BUILDING_MATERIALS: 'D',
  // 化妆品
  COSMETICS: 'E'
}

export const accreditationStandardName = {
  // 工业消费品
  'A': '工业消费品',
  // 农食产品
  'B': '农食产品',
  // 服务
  'C': '服务',
  // 绿色建材
  'D': '绿色建材',
  // 化妆品
  'E': '化妆品'
}

export const standardStatus	 = {
  // 失效
  EXPIRED: '0',
  // 现行有效
  CURRENTLY_VALID: '1',
  // 已被修改
  MODIFIED: '2',
  // 尚未生效
  NOT_YET_VALID: '3',
  // 部分失效
  PARTIALLY_EXPIRED: '4'
}

export const standardStatusName	 = {
  // 失效
  '0': '失效',
  // 现行有效
  '1': '现行有效',
  // 已被修改
  '2': '已被修改',
  // 尚未生效
  '3': '尚未生效',
  // 部分失效
  '4': '部分失效'
}
