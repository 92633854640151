<template>
  <div class="bg">
    <div class="main-box">
      <div class="title">
        <div>认证标准</div>
        <div class="bottom-line"></div>
      </div>
      <div class="select-box">
        <div
          :class="{'select-btn':selectType ==='normal','normal-btn':selectType==='special'}"
          @click="handleSelect('normal')">
          <span>实施规则</span>
          <img
            v-if="selectType ==='normal'"
            src="@/assets/index/technicalnorm/zhunze.png"
            alt="">
          <img
            v-else
            src="@/assets/index/technicalnorm/zhunze-active.png"
            alt="">
        </div>
        <div
          :class="{'normal-btn':selectType ==='normal','select-btn':selectType==='special'}"
          @click="handleSelect('special')">
          <span>技术规范</span>
          <img
            v-if="selectType ==='special'"
            src="@/assets/index/technicalnorm/guifan-active.png"
            alt="">
          <img
            v-else
            src="@/assets/index/technicalnorm/guifan.png"
            alt="">
        </div>
      </div>
      <div class="search-box">
        <el-input
          placeholder="请输入关键字查询"
          v-model="searchValue"
          clearable>
          <el-button slot="append" icon="el-icon-search" @click="searchData">搜索</el-button>
        </el-input>
      </div>
      <div class="filter-box">
        <div class="list-area">
          <div class="pos">
            <div class="type-title">产品类型：</div>
            <div class="item-list" ref="categoryList">
            <span
              class="item"
              :class="{'active-item':activeCategory===allKey.category.id}"
              @click="changeCategory(allKey.category)">{{ allKey.category.name }}</span>
              <span
                class="item"
                v-for="(item) in category"
                :key="item.id"
                :class="{'active-item':activeCategory===item.id}"
                @click="changeCategory(item)">{{ item.name }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="table-box">
        <el-table
          v-loading="loading"
          :data="tableData"
          style="width: 100%">
          <el-table-column
            prop="standardNumber"
            align="center"
            label="标准编号"
            width="240">
          </el-table-column>
          <el-table-column
            prop="standardName"
            align="center"
            label="标准名称"
            width="280">
          </el-table-column>
          <el-table-column
            prop="productType"
            align="center"
            label="产品类型"
            width="180"
          >
          </el-table-column>
          <el-table-column
            prop="standardState"
            align="center"
            width="180"
            label="标准状态">
          </el-table-column>
          <el-table-column
            prop="releaseTime"
            align="center"
            width="180"
            label="发布时间">
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="100">
            <template slot-scope="scope">
              <el-button @click="handleClick(scope.row)" type="text" size="small">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination-box">
        <p-pagination-base
          :currentPage.sync="pagination.currentPage"
          :pageSize.sync="pagination.pageSize"
          :page-sizes="[20, 30, 40]"
          :total="pagination.totalSize"
          v-show="!this.isEmpty"
          @size-change="handleSizeChange"
          @currentPageChange="changeCurrent"/>
      </div>
    </div>
  </div>
</template>

<script>
  import PPaginationBase from '@comp/project/pagination/PPaginationBase'
  import {
    standardsData
  } from '@/api/mock/homeIndex'
  import { getAction } from '@api/manage'
  import { message } from 'ant-design-vue'
  import { accreditationStandardName, standardStatusName } from '@/enum/accreditationStandard'

  export default {
    name: 'Technicalnorm',
    components: { PPaginationBase },
    data() {
      return {
        loading: false,
        searchValue: '',
        typeValue: '',
        pagination: {
          currentPage: 1,
          pageSize: 10,
          totalSize: 0
        },
        allKey: {
          category: {
            name: '全部',
            id: null
          }
        },
        selectType: 'normal',
        activeCategory: '',
        category: [
          {
            id: 'A',
            name: '工业消费品'
          },
          {
            id: 'B',
            name: '农食产品'
          },
          {
            id: 'C',
            name: '服务'
          },
          {
            id: 'D',
            name: '绿色建材'
          }
        ],
        tableData: [],
        standardsData: standardsData
      }
    },
    computed: {
      isEmpty() {
        return this.tableData.length === 0
      }
    },
    created() {
      this.loadData(1)
      this.activeCategory = this.allKey.category.id
    },
    watch: {
      selectType: {
        handler() {
          this.loadData()
        },
        deep: true
      }
    },
    methods: {
      changeCategory(item) {
        this.activeCategory = item.id
        this.loadData(1)
      },
      changeCurrent(page) {
        this.pagination.currentPage = page
        this.loadData(page)
      },
      handleSizeChange(val) {
        this.pagination.pageSize = val
        this.loadData(1)
      },
      loadData(arg, isLoading = true) {
        return new Promise(resolve => {
          if (isLoading) {
            this.loading = true
          }
          if (arg === 1) {
            this.pagination.currentPage = 1
          }
          let params = {
            queryPage: this.pagination.currentPage,
            querySize: this.pagination.pageSize,
            certificationCategory: this.activeCategory || null,
            queryContent: this.searchValue || null,
            standardType: this.selectType === 'normal' ? '0' : '1'
          }
          let fun = this.$OpenCC.Converter({ from: 'tw', to: 'cn' })
          if (params.queryContent) {
            // 将繁体文字转化成简体请求接口
            params.queryContent = fun(params.queryContent)
          }
          getAction('/api/v1/dms/standard/brief', params).then((res) => {
            if (res.success) {
              if (res && res.list && res.list.length > 0) {
                this.tableData = res.list.map(item => {
                  return {
                    id: item.id,
                    standardNumber: item.number || '-',
                    releaseTime: item.releaseTime.slice(0, 10) || '-',
                    productType: accreditationStandardName[item.certificationCategory] || '-',
                    standardState: standardStatusName[item.standardStatus] || '-',
                    standardName: item.name || '-',
                    publishTime: item.releaseTime || '-',
                    views: item.viewCount || '-'
                  }
                })
                this.pagination.currentPage = res.page
                this.pagination.pageSize = res.size
                this.pagination.totalSize = res.totalSize
              } else {
                this.tableData = []
              }
            } else {
              this.tableData = []
              message.error(res.message)
            }
          }).catch((err) => {
            this.tableData = []
            message.error(err.message)
          }).finally(() => {
            this.loading = false
          })
          // 关键字筛选数据
          if (params.queryContent) {
            this.tableData = this.tableData.filter(item => item.standardName.indexOf(params.queryContent) > -1)
          }
          // 产品类型筛选数据
          if (params.certificationCategory && params.certificationCategory !== this.allKey.category.name) {
            this.tableData = this.tableData.filter(item => item.productType === params.certificationCategory)
          }
          this.$nextTick(() => {
            this.$zh_tran(localStorage.getItem('lang'))
          })
        })
      },
      searchData() {
        this.loadData(1)
      },
      handleSelect(val) {
        this.selectType = val
        this.searchValue = ''
        this.activeCategory = this.allKey.category.id
      },
      handleClick(data) {
        console.log(data)
        this.$router.push({
          path: '/bayareacertification/technicalnormDetail',
          query: {
            id: data.id,
            // type: this.selectType
          }
        })
      }
    }
  }
</script>

<style lang="less" scoped>
.bg {
  width: 100%;
  margin-top: 85px;
  background: url("~@/assets/index/technicalnorm/technicalnorm-bg.png") no-repeat;
}

.main-box {
  width: 100%;
}

.select-box {
  width: 500px;
  display: flex;
  justify-content: space-between;
  margin: 10px auto 30px;

  .select-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 60px;
    line-height: 50px;
    border-radius: 20px;
    background-color: @primary-color;
    color: rgba(250, 250, 250, 1);
    font-size: 24px;
    text-align: left;
    border: 1px solid rgba(187, 187, 187, 1);
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  .normal-btn {
    .select-btn();
    background-color: rgba(207, 208, 217, 1);
    color: rgba(16, 16, 16, 1);
  }
}

.title {
  padding: 33px 0;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .bottom-line {
    width: 30px;
    margin-top: 8px;
    border-bottom: 3px solid @primary-color;
  }
}

.search-box {
  width: 1200px;
  margin: 0 auto;
}

.table-box {
  width: 1200px;
  margin: 0 auto;
  font-size: 16px;
}

.filter-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 1200px;
  margin: 20px auto;
  height: 80px;

  .btn-bgc {
    display: inline-block;
    width: 89px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 4px;
    background-color: rgba(45, 108, 163, 0.51);
    color: rgba(250, 250, 250, 1);
    font-size: 16px;
    margin-right: 20px;
  }
}

.pagination-box {
  text-align: center;
  margin-bottom: 30px;
}

.pos {
  width: 100%;
  padding-top: 8px;
  overflow: hidden;
  display: flex;
  font-size: 16px;
  transition: all .3s;
}


.type-title {
  margin: 0 14px;
  line-height: 28px;
  white-space: nowrap;
  font-weight: bold;
}


.item-list {
  display: flex;
  flex-wrap: wrap;


  .item {
    height: 32px;
    color: #545c63;
    padding: 4px 12px;
    margin: 0 12px 8px 0;
    border-radius: 6px;
  }

  .active-item {
    color: #FFFFFF;
    background-color: @primary-color;
  }

  .item:hover {
    cursor: pointer;
  }
}

/deep/ .el-table {
  font-size: 16px !important;
}

/deep/ .el-button--small {
  font-size: 16px;
}

/deep/ .el-input-group__append button.el-button {
  border-color: @primary-color;
  background-color: @primary-color;
  color: #ffffff;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/deep/ .el-button--text {
  color: @primary-color;
}

/deep/ .el-input__icon {
  font-size: 16px;
}
</style>
